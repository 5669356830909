import { StoryblokResult, StoryData } from 'storyblok-js-client';
import storyapi from '@/storyapi';
import { SponsorModel } from '@/models/sponsor.model';

export function fetchArticles(page: number): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'articles/',
    resolve_relations: 'authors',
    sort_by: 'content.title:asc',
    page: page,
    version: process.env.VUE_APP_VERSION,
  });
}

export function fetchArticle(slug: string): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'articles/',
    by_slugs: '*/' + slug,
    resolve_relations: 'authors',
    version: process.env.VUE_APP_VERSION,
  });
}

export function fetchAuthor(name: string): Promise<StoryblokResult> {
  return storyapi.get(`cdn/stories/authors/${name}`, {
    version: process.env.VUE_APP_VERSION,
  });
}

export function fetchArticlesByAuthor(authorUuids: string, page: number): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'articles/',
    version: process.env.VUE_APP_VERSION,
    resolve_relations: 'authors',
    sort_by: 'content.title:asc',
    page: page,
    filter_query: {
      authors: {
        in_array: authorUuids,
      },
    },
  });
}

export function fetchArticlesByTopic(topicName: string, page: number): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'articles/',
    resolve_relations: 'authors',
    with_tag: topicName,
    page: page,
    sort_by: 'content.title:asc',
    version: process.env.VUE_APP_VERSION,
  });
}

export function fetchSponsors(): Promise<StoryData<SponsorModel>[]> {
  return storyapi.getAll('cdn/stories', {
    starts_with: 'sponsors/',
    sort_by: 'content.name:asc',
    version: process.env.VUE_APP_VERSION,
  });
}

export function fetchTags(): Promise<StoryblokResult> {
  return storyapi.get('cdn/tags/', {
    version: process.env.VUE_APP_VERSION,
  });
}

export function searchArticles(searchBy: string): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'articles/',
    resolve_relations: 'author',
    search_term: searchBy,
    per_page: 5,
    version: process.env.VUE_APP_VERSION,
  });
}

export function searchAuthors(searchBy: string): Promise<StoryblokResult> {
  return storyapi.get('cdn/stories', {
    starts_with: 'authors/',
    search_term: searchBy,
    per_page: 5,
    version: process.env.VUE_APP_VERSION,
  });
}
