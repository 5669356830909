import { createRouter, createWebHistory, RouteLocationNormalized, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/articles',
    name: 'Articles',
    component: () => import(/* webpackChunkName: "ArticlesView" */ '../views/ArticlesView.vue'),
  },
  {
    path: '/articles/:slug',
    name: 'Article',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ArticleDetailView" */ '../views/ArticleDetailView.vue'),
  },
  {
    path: '/topics/:slug',
    name: 'Topics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "TopicsView" */ '../views/TopicsView.vue'),
  },
  {
    path: '/authors/:slug',
    name: 'Authors',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "AuthorsView" */ '../views/AuthorsView.vue'),
  },
  {
    path: '/sponsors',
    name: 'Sponsors',
    component: () => import(/* webpackChunkName: "SponsorsView" */ '../views/SponsorsView.vue'),
  },
  { path: '/', redirect: '/articles' },
];

const router = createRouter({
  scrollBehavior(to: RouteLocationNormalized) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (to.hash) {
          resolve({ el: to.hash, top: 122 });
        }

        resolve({});
      }, 500);
    });
  },
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
