import { Ref } from '@vue/reactivity';
import { computed, ComputedRef, ref } from 'vue';
import { Auth } from '@aws-amplify/auth';
import { onAuthUIStateChange } from '@aws-amplify/ui-components';
import { AuthState } from '@aws-amplify/ui-components/dist/types/common/types/auth-types';

export default function() {
  const authState: Ref<string> = ref('');
  const signedIn: ComputedRef<boolean> = computed(() => authState.value === 'signedin');

  const logout = () => {
    Auth.signOut();
  };

  onAuthUIStateChange((state: AuthState) => {
    authState.value = state;
  });

  return {
    logout,
    signedIn,
  };
}
