
import { defineComponent, ref } from 'vue';
import debounce from 'lodash/debounce';
import IconSearch from '@/components/ui/icons/IconSearch.vue';

export default defineComponent({
  components: { IconSearch },
  props: {
    search: {
      type: Function,
      required: true,
    },
    linkToEntity: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const searchInput = ref<string>('');
    const suggestions = ref<any[]>([]);

    const onInputChange = debounce(async function(searchTerm: string) {
      searchInput.value = searchTerm;
      suggestions.value = await props.search(searchInput.value);
    }, 300);

    const onInputBlur = () => {
      setTimeout(() => (suggestions.value = []), 300);
    };

    const clearInput = () => {
      searchInput.value = '';
    };

    return {
      searchInput,
      suggestions,
      onInputChange,
      onInputBlur,
      clearInput,
    };
  },
});
