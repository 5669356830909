<template>
  <header class="header">
    <div class="header__nav-wrapper">
      <div class="logo-wrapper">
        <router-link to="/articles" class="logo-wrapper__logo">
          <img class="h-10 md:h-16" src="../../assets/logos/logo.png" />
        </router-link>
      </div>
      <div v-if="signedIn" class="nav">
        <ul>
          <li class="nav__item">
            <router-link to="/sponsors">
              Sponsoren
            </router-link>
          </li>
          <li v-if="signedIn" class="nav__item">
            <button @click="logout" class="btn-logout">
              <span class="hidden md:block mr-1">Logout</span>
              <IconLogout class="w-5" />
            </button>
          </li>
        </ul>
      </div>
    </div>
    <div v-if="signedIn" class="flex">
      <div class="flex border-r">
        <button
          @click="toggleSearchBy('articles')"
          class="btn-toggle-search"
          :class="{ 'btn-toggle-search--active': isSearchByArticlesActive }"
        >
          <IconArticles />
        </button>
        <button
          @click="toggleSearchBy('authors')"
          class="btn-toggle-search"
          :class="{ 'btn-toggle-search--active': isSearchByAuthorsActive }"
        >
          <IconAuthors />
        </button>
      </div>
      <SearchBox class="flex-1" :search="fetchSuggestions" :link-to-entity="createUrlToEntity">
        <template v-slot:default="{ suggestion, clearInput }">
          <div v-if="isSearchByArticlesActive" class="overflow-ellipsis overflow-hidden" @click="clearInput()">
            {{ suggestion.content.title }}
          </div>
          <div v-else class="overflow-ellipsis overflow-hidden" @click="clearInput()">
            {{ suggestion.content.name }}
          </div>
        </template>
      </SearchBox>
    </div>
  </header>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import SearchBox from '@/components/ui/SearchBox.vue';
import IconLogout from '@/components/ui/icons/IconLogout.vue';
import IconAuthors from '@/components/ui/icons/IconAuthors.vue';
import IconArticles from '@/components/ui/icons/IconArticles.vue';
import handleLoginLogout from '@/composables/handleLoginLogout';
import useSearchByArticlesAndAuthors from '@/composables/useSearchByArticlesAndAuthors';

export default defineComponent({
  components: {
    IconArticles,
    IconAuthors,
    IconLogout,
    SearchBox,
  },
  setup() {
    return {
      ...handleLoginLogout(),
      ...useSearchByArticlesAndAuthors(),
    };
  },
});
</script>

<style scoped>
.header {
  @apply w-full sticky z-10 top-0 bg-white pt-2 border-b border-gray-400 shadow flex flex-col;
}

.header__nav-wrapper {
  @apply flex items-center justify-between px-2 pb-2 border-b border-gray-400 shadow;
}

.logo-wrapper {
  @apply flex items-center flex-1;
}

.logo-wrapper__logo {
  @apply text-2xl text-white font-semibold h-full;
}

.nav {
  @apply flex lg:flex-grow items-center;
}

.nav ul {
  @apply flex list-none items-center ml-auto;
}

.nav__item {
  @apply px-3 py-2 flex items-center text-xs uppercase font-bold leading-snug;
}

.btn-logout {
  @apply bg-brand-500 hover:bg-brand-700 text-white font-bold py-2 px-4 rounded px-3 py-2 flex items-center text-xs uppercase font-bold;
}

.btn-toggle-search {
  @apply w-8 px-1 hover:bg-brand-500 hover:text-white;
}

.btn-toggle-search--active {
  @apply bg-brand-500 text-white;
}
</style>
