// 1. Require the Storyblok client
import StoryblokClient from 'storyblok-js-client';
import { Auth } from '@aws-amplify/auth';

// 4. Initialize the Storyblok Client Bridge to allow us to subscribe to events
// from the editor itself.
window.storyblok.init();

// 3. Initialize the client with the preview token so we can access our API easily
// from your space dashboard at https://app.storyblok.com
const storyapi = new StoryblokClient({}, process.env.VUE_APP_URL);

storyapi.client.interceptors.request.use(async config => {
  config.headers = {
    Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
    ...config.headers,
  };
  return config;
});

export default storyapi;
