
import { defineComponent } from 'vue';
import SearchBox from '@/components/ui/SearchBox.vue';
import IconLogout from '@/components/ui/icons/IconLogout.vue';
import IconAuthors from '@/components/ui/icons/IconAuthors.vue';
import IconArticles from '@/components/ui/icons/IconArticles.vue';
import handleLoginLogout from '@/composables/handleLoginLogout';
import useSearchByArticlesAndAuthors from '@/composables/useSearchByArticlesAndAuthors';

export default defineComponent({
  components: {
    IconArticles,
    IconAuthors,
    IconLogout,
    SearchBox,
  },
  setup() {
    return {
      ...handleLoginLogout(),
      ...useSearchByArticlesAndAuthors(),
    };
  },
});
