<template>
  <div class="search-input-container">
    <div class="search-input-container__input-wrapper">
      <IconSearch class="search-input-container__search-icon" />
      <!-- Search Input -->
      <!-- cannot use v-model because of vue bug for mobile devices -->
      <!--- https://github.com/devstark-com/vue-textarea-autosize/issues/34 --->
      <input
        :input="searchInput"
        role="search"
        placeholder="Search..."
        class="search-input-container__search-input"
        @input="onInputChange($event.target.value)"
        @blur="onInputBlur"
      />
    </div>

    <!-- Suggestions list -->
    <div class="relative">
      <div class="search-input-container__suggestion-list">
        <router-link
          v-for="suggestion in suggestions"
          :key="suggestion.id"
          :to="linkToEntity(suggestion)"
          class="search-input-container__suggestion-list__suggestion-list-item"
        >
          <slot :suggestion="suggestion" :clearInput="clearInput"></slot>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue';
import debounce from 'lodash/debounce';
import IconSearch from '@/components/ui/icons/IconSearch.vue';

export default defineComponent({
  components: { IconSearch },
  props: {
    search: {
      type: Function,
      required: true,
    },
    linkToEntity: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const searchInput = ref<string>('');
    const suggestions = ref<any[]>([]);

    const onInputChange = debounce(async function(searchTerm: string) {
      searchInput.value = searchTerm;
      suggestions.value = await props.search(searchInput.value);
    }, 300);

    const onInputBlur = () => {
      setTimeout(() => (suggestions.value = []), 300);
    };

    const clearInput = () => {
      searchInput.value = '';
    };

    return {
      searchInput,
      suggestions,
      onInputChange,
      onInputBlur,
      clearInput,
    };
  },
});
</script>

<style scoped>
.search-input-container {
  @apply relative h-10;
}

.search-input-container__input-wrapper {
  @apply h-full flex items-center;
}

.search-input-container__search-icon {
  @apply block absolute text-gray-600 right-0 z-10 h-4 mr-3;
}

.search-input-container__search-input {
  @apply w-full bg-white text-gray-700 outline-none focus:border-gray-600 px-4 py-1;
}

.search-input-container__suggestion-list {
  @apply absolute z-30 bg-white top-0 inset-x-0 rounded shadow-lg mt-1;
}

.search-input-container__suggestion-list__suggestion-list-item {
  @apply block truncate text-gray-700 hover:text-main hover:bg-gray-100 px-4 py-2;
}
</style>
