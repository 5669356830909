import { Ref } from '@vue/reactivity';
import { computed, ComputedRef, ref } from 'vue';
import { StoryblokResult, StoryData } from 'storyblok-js-client';
import { ArticleModel } from '@/models/article.model';
import { AuthorModel } from '@/models/author.model';
import { searchArticles, searchAuthors } from '@/composables/api';

export default function() {
  const searchBy: Ref<'articles' | 'authors'> = ref('articles');
  const isSearchByArticlesActive: ComputedRef<boolean> = computed(() => searchBy.value === 'articles');
  const isSearchByAuthorsActive: ComputedRef<boolean> = computed(() => searchBy.value === 'authors');

  const toggleSearchBy = (newSearchBy: 'authors' | 'articles') => {
    searchBy.value = newSearchBy;
  };

  const fetchSuggestions = async (searchInput: string): Promise<StoryData<ArticleModel | AuthorModel>[]> => {
    const { data }: StoryblokResult = isSearchByAuthorsActive.value
      ? await searchAuthors(searchInput)
      : await searchArticles(searchInput);

    return data.stories;
  };

  const createUrlToEntity = (suggestion: StoryData<ArticleModel | AuthorModel>) => {
    if (isSearchByArticlesActive.value) {
      return `/articles/${suggestion.slug}`;
    } else {
      return `/authors/${suggestion.slug}`;
    }
  };

  return {
    isSearchByArticlesActive,
    isSearchByAuthorsActive,
    fetchSuggestions,
    createUrlToEntity,
    toggleSearchBy,
  };
}
