
import { defineComponent } from 'vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheLogin from '@/components/ui/TheLogin.vue';

export default defineComponent({
  components: {
    TheLogin,
    TheHeader,
  },
});
