<template>
  <div data-testid="layout-container">
    <TheHeader />
    <amplify-authenticator username-alias="email">
      <TheLogin />
      <div class="mt-4">
        <section>
          <div class="mx-auto px-4">
            <router-view />
          </div>
        </section>
      </div>
    </amplify-authenticator>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import TheHeader from '@/components/layout/TheHeader.vue';
import TheLogin from '@/components/ui/TheLogin.vue';

export default defineComponent({
  components: {
    TheLogin,
    TheHeader,
  },
});
</script>
