/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:8314c7f5-1d59-4266-8618-7c43af3b2a5b",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_F5EG4a9ss",
    "aws_user_pools_web_client_id": "67v8rmba205s32nst414o8i9qm",
    "oauth": {},
    "aws_cloud_logic_custom": [
        {
            "name": "neograzApi",
            "endpoint": "https://jxhk6vo1pd.execute-api.eu-central-1.amazonaws.com/prod",
            "region": "eu-central-1"
        }
    ]
};


export default awsmobile;
